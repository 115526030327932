import React from 'react'
import { Link } from 'gatsby'

// Images
import ArrowSecondary from 'img/arrow_secondary.inline.svg'

// Components
import ParseContent from 'components/shared/ParseContent'
import GravityForm from 'components/shared/GravityForm'
import Share from 'components/elements/Share'

// Third Party
import styled from 'styled-components'

const StyledBlogStory = styled.div`
  position: relative;
  padding: 0 0 150px 0;

  @media screen and (max-width: 768px) {
    padding: 0 0 100px 0;
  }

  @media screen and (max-width: 576px) {
    padding: 0 0 50px 0;
  }
`

const BlueBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 3000px;
  background-color: ${(props) => props.theme.color.face.main};
  height: 200px;
  z-index: -1;
`

const Story = styled.div`
  background-color: ${(props) => props.theme.color.face.light};
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  border-radius: 29px;
  padding: 40px 80px 60px 80px;
  position: relative;
  z-index: 1;

  & h1 {
    font-size: ${(props) => props.theme.font.size[30]};
    font-weight: ${(props) => props.theme.font.weight.xl};
    line-height: 40px;
    padding-bottom: 10px;
  }

  & h2 {
    font-size: ${(props) => props.theme.font.size[20]};
    line-height: 25px;
    padding-bottom: 0;
  }

  & td {
    border: 1px solid ${(props) => props.theme.color.face.dark};
    vertical-align: top;
    padding: 15px;
  }

  & a {
    color: ${(props) => props.theme.color.text.contrast};

    &:hover {
      color: ${(props) => props.theme.color.text.contrast};
      text-decoration: underline;
    }
  }

  & li {
    color: ${(props) => props.theme.color.text.dark};
    font-size: 14px;
    line-height: 24px !important;

    &:before {
      height: 14px !important;
      width: 14px !important;
      border-width: 2px !important;
      top: 5px !important;
      left: -25px !important;
    }
  }

  & img {
    margin: 20px 0 20px 0;
  }

  @media screen and (max-width: 768px) {
    padding: 30px 40px 30px 40px;
  }

  @media screen and (max-width: 576px) {
    padding: 30px 15px 30px 15px;
  }
`

const Content = styled(ParseContent)`
  & p {
    color: ${(props) => props.theme.color.text.dark};
    line-height: 30px;
    margin-top: 40px;

    b,
    strong {
      font-size: ${(props) => props.theme.font.size[20]};
    }
  }
`

const SubContent = styled(ParseContent)`
  & p {
    line-height: 20px;
  }
`

interface BlogStoryProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_BlogStory
  location: any
  title: string
}

const BlogStory: React.FC<BlogStoryProps> = ({ title, fields, location }) => (
  <StyledBlogStory>
    <BlueBar />
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-10">
          <Story>
            <Link
              className="d-flex pb-4 align-items-center text-contrast"
              to={`${
                fields?.category === 'workingat'
                  ? '/werken-bij-senseanywhere/'
                  : '/blog/'
              }`}
            >
              <ArrowSecondary />
              <div className="ml-2">Back to overview</div>
            </Link>
            <Content content={fields.description} />
            <div className="pb-4 pt-5">
              <Share
                title="Share this article"
                returnUrl={`${
                  fields?.category === 'workingat'
                    ? '/werken-bij-senseanywhere/'
                    : '/blog/'
                }`}
                location={location}
              />
            </div>
            <SubContent content={fields.subDescription} />
            <div className="row pt-3 justify-content-center">
              <div className="col-lg-8">
                <GravityForm newsletter id={fields.form || 2} />
              </div>
            </div>
          </Story>
        </div>
      </div>
    </div>
  </StyledBlogStory>
)

export default BlogStory
